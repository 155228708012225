var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-group",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitted.apply(null, arguments)
        }
      }
    },
    [
      _c("h1", [_vm._v("Forgot password")]),
      _c("p", { staticClass: "sub-text" }, [
        _vm._v(
          " To reset your password, enter the email or phone number you used to Sign Up and then hit Submit. We will then send a verification code. "
        )
      ]),
      _c("v-text-field", {
        attrs: {
          label: "Email or Phone number",
          hint: _vm.usernameHint,
          "error-messages": _vm.error,
          value: _vm.username
        },
        model: {
          value: _vm.username,
          callback: function($$v) {
            _vm.username = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "username"
        }
      }),
      _c(
        "div",
        { staticClass: "submit-button" },
        [
          _c(
            "inqli-button",
            {
              attrs: { width: "100%", isProcess: _vm.isProcessing },
              on: { click: _vm.submitted }
            },
            [_vm._v("Submit")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }