var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      attrs: { showTopBanner: false, showSidebar: false },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("app-header", {
                attrs: { leftLogoPersist: true, showMobileMenuButton: false }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [_c("div", { staticClass: "container" }, [_c("forgot-password-form")], 1)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }