<template>
  <layout :showTopBanner="false" :showSidebar="false">
    <template #header>
      <app-header
        :leftLogoPersist="true"
        :showMobileMenuButton="false"
      ></app-header>
    </template>
    <div class="container">
      <forgot-password-form />
    </div>
  </layout>
</template>

<script>
import Header from "../app/components/headers/DefaultHeader";
import DefaultLayout from "../app/components/layouts/DefaultLayout";
import ForgotPasswordForm from "./components/ForgotPasswordForm.vue";

export default {
  components: {
    "app-header": Header,
    layout: DefaultLayout,
    "forgot-password-form": ForgotPasswordForm,
  },
  metaInfo: {
    title: "Forgot Password",
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 450px;
  width: 50%;
  flex: 1;
  margin: 0 auto;
  margin-top: 45px;
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    width: 90%;
  }
}
</style>
