<template>
  <form class="form-group" @submit.prevent="submitted">
    <h1>Forgot password</h1>
    <p class="sub-text">
      To reset your password, enter the email or phone number you used to Sign
      Up and then hit Submit. We will then send a verification code.
    </p>
    <v-text-field
      v-model.trim="username"
      label="Email or Phone number"
      :hint="usernameHint"
      :error-messages="error"
      :value="username"
    />
    <div class="submit-button">
      <inqli-button width="100%" :isProcess="isProcessing" @click="submitted"
        >Submit</inqli-button
      >
    </div>
  </form>
</template>

<script>
import Button from "../../app/components/buttons/Button";
import { required } from "vuelidate/lib/validators";
import { isValidUsername } from "../../app/utils/validation-helper";
import { Auth } from "aws-amplify";

export default {
  name: "ForgotPasswordForm",
  components: {
    "inqli-button": Button,
  },
  metaInfo: {
    title: "Forgot Password",
  },
  data() {
    return {
      username: "",
      isProcessing: false,
      error: "",
    };
  },
  validations: {
    username: {
      required,
      isValidUsername,
    },
  },
  computed: {
    isPhoneNumber() {
      const val = this.username;
      return !isNaN(val) || (val.includes("+") && !isNaN(val.substring(1)));
    },
    usernameHint() {
      if (this.isPhoneNumber) {
        return "Phone number must have country code, no spaces or dashes.";
      }
      return "";
    },
  },
  watch: {
    username(val) {
      this.username = val.trim().toLowerCase();
      this.error = false;
      this.validateInput();
    },
  },
  methods: {
    validateInput() {
      if (this.$v.$invalid) {
        if (!this.$v.username.required) {
          this.error = "Please enter your email or phone number.";
        } else if (!this.$v.username.isValidUsername) {
          this.error = "Please enter a valid email or phone number.";
        }
      } else {
        this.error = "";
      }
    },
    async submitted() {
      try {
        if (!this.isProcessing) {
          this.isProcessing = true;
          // validate username
          if (!this.$v.$invalid) {
            // format the final username
            this.username = this.formatUsernameForPhone(this.username);
            // if username is validate submit to cognito
            await Auth.forgotPassword(this.username);
            this.$router.push(
              `/forgot-password-verification?username=${this.username}`
            );
          } else {
            this.validateInput();
          }
        }
      } catch (e) {
        // ignore this error to ensure security
        this.$router.push(
          `/forgot-password-verification?username=${this.username}`
        );
      }
      this.isProcessing = false;
    },
    formatUsernameForPhone(val) {
      if (this.isPhoneNumber && !val.includes("+")) {
        return val.length <= 10 ? `+1${val}` : `+${val}`;
      }
      return val;
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-text {
  margin-bottom: 30px;
}
.submit-button {
  margin-top: 15px;
}
</style>
